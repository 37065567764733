<template>
  <el-container class="supervisor-add">
    <el-header class="box add bb">
       <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item>监管部门</el-breadcrumb-item>
         <el-breadcrumb-item >监管部门列表</el-breadcrumb-item>
        <el-breadcrumb-item>监管员列表</el-breadcrumb-item>
         <el-breadcrumb-item >新增监管员</el-breadcrumb-item>
          </el-breadcrumb>
      <div>
        <el-button class size="small" type="primary" @click="submitUser">提交</el-button>
        <el-button class size="small" @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="">
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4">
            <el-form-item size="medium">
              <el-input clearable placeholder="请输入姓名" v-model="page.user_name" class="input-with-select"
                        @change="onSubmit(page.user_name)"></el-input>
            </el-form-item>
          </el-col>          <el-col :span="4">
            <el-form-item size="medium">
              <el-input clearable placeholder="请输入手机号" v-model="page.user_mobilephone" class="input-with-select"
                        @change="onSubmit(page.user_mobilephone)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button type="primary" @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list">
        <el-table
          border
          :data="tableData"
          ref="userTable"
          @selection-change="handleSelectionChange"
          style="width: 100%"
          :row-key="getRowKeys"
          fit
        >
          <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
          <el-table-column type="index" width="120" label="序号" align="center"></el-table-column>
          <el-table-column prop="user_name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="user_loginname" label="手机号" align="center"></el-table-column>
          <el-table-column label="性别" align="center">
            <template slot-scope="scope">{{scope.row.user_sex==1?'男':'女'}}</template>
          </el-table-column>
          <el-table-column label="设置为网格员" fixed="right" width="120px" align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" active-color="#409EFF" inactive-color="#ccc" @change="changeStatus(scope.row,scope.$index)"> </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr mt20">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10,20,30,50]"
            :page-size="parseInt(page.pager_openset)"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { mapGetters } from "vuex";

import { session } from "../../../util/util";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        user_name: "",
        user_type: "2",
        user_mobilephone:"",
        query_param:'not_in_departmet_manage_user',
        department_id:""
      },
      department_id: '',
      checkAll: false,
      checkedList: [],
      isIndeterminate: false,
      departmentRegions: [],
      department: {},
      total: 0,
      tableData: [],
      multipleSelection: [],
      getRowKeys(row) {
        return row.user_code;
      }
    };
  },
  computed: {},
  filters: {
    stateFilter(state) {
      const stateMap = {
        "1": "启用",
        "2": "禁用"
      };
      return stateMap[state];
    }
  },
  mounted() {
       this.page.department_id = session.get('params1').department_id?String(session.get('params1').department_id):'';
     this.init(this.page);

    // this.fetchRegionList();
  },
  methods: {
    init(params) {
      axios.get("/pc/user/list", params).then(response => {
        // this.tableData = response.data.user_list;
         this.tableData=response.data.user_list.map(item => {
                return {...item, status: false}
              })
        this.total = response.data.pager_count;
      });
    },
    onSubmit () {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    changeStatus(row,index){
			// row.status = true
	},
    submitUser() {
      let department_user_list = [];
      this.multipleSelection.forEach(item => {
        department_user_list.push({
          department_id:this.page.department_id,
          user_id: item.user_id,
          user_tag:item.status?'2':'1'
        });
      });
      axios.post("/pc/department-user/save", { department_user_list }).then(response => {
          this.$router.back();
        });
    },
    handleCurrentChange(val) {
      this.page.pager_offset = (val - 1) * this.page.pager_openset + "";
      this.init(this.page);
    },
    handleSizeChange(val) {
      this.page.pager_openset = val + "";
      this.init(this.page);
    },
    onSearch() {
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  }
};
</script>
<style lang="scss" scoped>
.supervisor-add {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }
}
</style>
